#related-pages {
  border-top: 1px solid $accent-inverted-active;
  border-bottom: 1px solid $accent-inverted-active;

  @media (prefers-color-scheme: dark) {
    border-top: 1px solid $accent-inverted-active-dark;
    border-bottom: 1px solid $accent-inverted-active-dark;
  }

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 3rem 0 0 0;

  @media screen and (max-width: 767px) {
    flex-flow: column nowrap;
  }
}

#related-page-previous,
#related-page-next {
  align-content: flex-start;
  align-items: baseline;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  padding: 0.5rem 0;

  @media screen and (min-width: 768px) {
    flex: 0 0 50%;
  }

  p {
    margin: 0;
  }
}

#related-page-previous {
  text-align: left;
  justify-content: flex-start;

  @media screen and (min-width: 768px) {
    border-right: 1px solid $accent-inverted-active;
    @media (prefers-color-scheme: dark) {
      border-right: 1px solid $accent-inverted-active-dark;
    }
    padding-right: 1rem;
  }

  .related-page-heading {
    margin-right: 0.5rem;
    order: 1;
  }

  .related-page-date {
    order: 2;
  }
}

#related-page-next {
  text-align: right;
  justify-content: flex-end;

  @media screen and (min-width: 768px) {
    padding-left: 1rem;
  }

  .related-page-heading {
    margin-left: 0.5rem;
    order: 2;
  }

  .related-page-date {
    order: 1;
  }
}

.related-page-heading {
  color: $text-color-subdued;
  @media (prefers-color-scheme: dark) {
    color: $text-color-subdued-dark;
  }

  font-weight: 600;
  font-size: 0.85rem;
  text-transform: uppercase;
}

.related-page-title {
  flex: 0 0 100%;
  font-weight: 400;
  padding: 0.1rem 0 0.1rem 0;
  order: 3;
}

.related-page-date {
  color: $text-color-subdued;
  @media (prefers-color-scheme: dark) {
    color: $text-color-subdued-dark;
  }
  font-size: 0.85rem;
}
