body {
  // Make sure body takes all the available vertical space
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
}

header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: baseline;

  @media screen and (max-width: 767px) {
    flex-flow: column nowrap;
    align-items: flex-start;
    overflow-x: hidden;
  }

  nav {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      // Show the list items horizontally
      // with some spacing inbetween
      list-style: none;
      li {
        font-size: 0.8rem;
        font-weight: bold;
        text-transform: uppercase;
        display: inline-block;
        margin: 0 0.8rem;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  padding: 1em 1em 2em 1em;
}

main {
  display: block;
  // Take all the extra vertical space if any.
  flex: 1;
  // Use as much horizontal space as possible and centered.
  width: 100%;
  margin: 0 auto;

  // Don't let the 100% width overflow on narrower devices.
  box-sizing: border-box;
  padding: 0 1em;

  .post-list {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 2em;
    }
  }
}

footer {
  padding: 2em 1em 1em 1em;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  ul {
    list-style-type: none;
    padding: 0;
  }
}
