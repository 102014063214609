// Remove all default margin
* {
  margin: 0;
}

#site-title {
  color: $accent-color;
  @media (prefers-color-scheme: dark) {
    color: $accent-color-dark;
  }

  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: -1px;

  &:hover,
  &:focus {
    outline: 0;
    color: $accent-inverted;
    background-color: $accent-color;

    @media (prefers-color-scheme: dark) {
      color: $accent-inverted-dark;
      background-color: $accent-color-dark;
    }
  }
}
