.tooltip {
  background-color: $background-color-dark;
  color: $text-color-dark;
  padding: 8px 16px;
  font-size: 80%;
  border-radius: 4px;
  max-width: 40ch;

  display: none;

  @media (prefers-color-scheme: dark) {
    background-color: $background-color;
    color: $text-color;
  }
}

.tooltip[data-show] {
  display: block;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

.tooltip[data-popper-placement^="top"] > .arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^="bottom"] > .arrow {
  top: -4px;
}

.tooltip[data-popper-placement^="left"] > .arrow {
  right: -4px;
}

.tooltip[data-popper-placement^="right"] > .arrow {
  left: -4px;
}

button.tooltip-btn {
  border: none;
  padding: 0;
  border-radius: 1rem;
  background-color: rgba(110, 110, 110, 0.4);
  margin: 0 0.1em 0 0.2em;

  &:hover {
    background-color: rgba(110, 110, 110, 0.8);
  }

  span {
    padding: 0.2rem 0.5rem;
    font-weight: bold;
    color: white;
  }
}

main {
  .post-content {
    .tooltip {
      p {
        margin: 0;
      }
    }
  }
}
